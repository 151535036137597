import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const ServiceIcon: FC<IconProps> = ({ className, width = 72, height = width }) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M39 46.2C39 39.9775 39 36.8662 40.977 34.9331C42.9541 33 46.136 33 52.5 33C58.864 33 62.0459 33 64.023 34.9331C66 36.8662 66 39.9775 66 46.2V52.8C66 59.0225 66 62.1338 64.023 64.0669C62.0459 66 58.864 66 52.5 66C46.136 66 42.9541 66 40.977 64.0669C39 62.1338 39 59.0225 39 52.8V46.2Z"
        fill="#16DB65"
      />
      <path
        d="M6 25.8C6 32.0225 6 35.1338 7.97703 37.0669C9.95406 39 13.136 39 19.5 39C25.864 39 29.0459 39 31.023 37.0669C33 35.1338 33 32.0225 33 25.8V19.2C33 12.9775 33 9.86619 31.023 7.9331C29.0459 6 25.864 6 19.5 6C13.136 6 9.95406 6 7.97703 7.9331C6 9.86619 6 12.9775 6 19.2V25.8Z"
        fill="#16DB65"
      />
      <path
        d="M39 16.5C39 13.2384 39 11.6076 39.5138 10.3212C40.1989 8.60602 41.5129 7.2433 43.1669 6.53284C44.4073 6 45.9799 6 49.125 6H55.875C59.0201 6 60.5927 6 61.8331 6.53284C63.4871 7.2433 64.8011 8.60602 65.4862 10.3212C66 11.6076 66 13.2384 66 16.5C66 19.7616 66 21.3924 65.4862 22.6788C64.8011 24.394 63.4871 25.7567 61.8331 26.4672C60.5927 27 59.0201 27 55.875 27H49.125C45.9799 27 44.4073 27 43.1669 26.4672C41.5129 25.7567 40.1989 24.394 39.5138 22.6788C39 21.3924 39 19.7616 39 16.5Z"
        fill="#16DB65"
      />
      <path
        opacity="0.5"
        d="M6 55.5C6 58.7616 6 60.3924 6.51381 61.6788C7.1989 63.394 8.51295 64.7567 10.1669 65.4672C11.4073 66 12.9799 66 16.125 66H22.875C26.0201 66 27.5927 66 28.8331 65.4672C30.4871 64.7567 31.8011 63.394 32.4862 61.6788C33 60.3924 33 58.7616 33 55.5C33 52.2384 33 50.6076 32.4862 49.3212C31.8011 47.606 30.4871 46.2433 28.8331 45.5328C27.5927 45 26.0201 45 22.875 45H16.125C12.9799 45 11.4073 45 10.1669 45.5328C8.51295 46.2433 7.1989 47.606 6.51381 49.3212C6 50.6076 6 52.2384 6 55.5Z"
        fill="#16DB65"
      />
    </SvgIcon>
  );
};
