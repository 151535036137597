import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const ShopIcon: FC<IconProps> = ({ className, width = 72, height = width }) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M31.6592 40.3408C30.3881 39.0697 28.8063 38.5516 27.0544 38.316C25.3945 38.0929 23.3037 38.0929 20.7963 38.093L17.4815 38.093C15.7394 38.093 14.2855 38.0929 13.1047 38.2052C11.8702 38.3226 10.7198 38.5773 9.66455 39.2239C8.63192 39.8567 7.76371 40.7249 7.13091 41.7576C6.48427 42.8128 6.22959 43.9632 6.11221 45.1977C5.99993 46.3786 5.99996 47.8326 6 49.5748V49.7795C5.99998 52.4309 5.99996 54.5691 6.16362 56.2904C6.3324 58.0655 6.68989 59.6121 7.54214 61.0029C8.40505 62.411 9.58897 63.5949 10.9971 64.4579C12.3879 65.3101 13.9345 65.6676 15.7096 65.8364C17.4309 66 19.5691 66 22.2205 66H22.4252C24.1673 66 25.6214 66.0001 26.8023 65.8878C28.0368 65.7704 29.1872 65.5157 30.2424 64.8691C31.2751 64.2363 32.1433 63.3681 32.7761 62.3355C33.4227 61.2802 33.6774 60.1298 33.7948 58.8953C33.9071 57.7145 33.907 56.2604 33.907 54.5183L33.907 51.2037C33.9071 48.6964 33.9071 46.6055 33.684 44.9456C33.4484 43.1937 32.9303 41.6119 31.6592 40.3408Z"
          fill="#16DB65"
        />
        <path
          d="M26.8023 6.11221C28.0368 6.22959 29.1872 6.48427 30.2424 7.13091C31.2751 7.76371 32.1433 8.63192 32.7761 9.66455C33.4227 10.7198 33.6774 11.8702 33.7948 13.1047C33.9071 14.2855 33.907 15.7397 33.907 17.4818L33.907 20.7964C33.9071 23.3037 33.9071 25.3945 33.684 27.0544C33.4484 28.8063 32.9303 30.3881 31.6592 31.6592C30.3881 32.9303 28.8063 33.4484 27.0544 33.684C25.3945 33.9071 23.3036 33.9071 20.7963 33.907L17.4813 33.907C15.7393 33.907 14.2855 33.9071 13.1047 33.7948C11.8702 33.6774 10.7198 33.4227 9.66455 32.7761C8.63192 32.1433 7.76371 31.2751 7.13091 30.2424C6.48427 29.1872 6.22959 28.0368 6.11221 26.8023C5.99993 25.6214 5.99996 24.1674 6 22.4252V22.2204C5.99998 19.5691 5.99996 17.4309 6.16362 15.7096C6.3324 13.9345 6.68989 12.3879 7.54214 10.9971C8.40505 9.58897 9.58897 8.40505 10.9971 7.54214C12.3879 6.68989 13.9345 6.3324 15.7096 6.16362C17.4309 5.99996 19.5692 5.99998 22.2205 6H22.4253C24.1675 5.99996 25.6214 5.99993 26.8023 6.11221Z"
          fill="#16DB65"
        />
        <path
          d="M49.7796 6H49.5748C47.8326 5.99996 46.3786 5.99993 45.1977 6.11221C43.9632 6.22959 42.8128 6.48427 41.7576 7.13091C40.7249 7.76371 39.8567 8.63192 39.2239 9.66455C38.5773 10.7198 38.3226 11.8702 38.2052 13.1047C38.0929 14.2856 38.093 15.7396 38.093 17.4817L38.093 20.7963C38.0929 23.3037 38.0929 25.3945 38.316 27.0544C38.5516 28.8063 39.0697 30.3881 40.3408 31.6592C41.6119 32.9303 43.1937 33.4484 44.9456 33.684C46.6055 33.9071 48.6963 33.9071 51.2036 33.907L54.5184 33.907C56.2605 33.907 57.7145 33.9071 58.8953 33.7948C60.1298 33.6774 61.2802 33.4227 62.3355 32.7761C63.3681 32.1433 64.2363 31.2751 64.8691 30.2424C65.5157 29.1872 65.7704 28.0368 65.8878 26.8023C66.0001 25.6214 66 24.1676 66 22.4255V22.2205C66 19.5691 66 17.4309 65.8364 15.7096C65.6676 13.9345 65.3101 12.3879 64.4579 10.9971C63.5949 9.58897 62.411 8.40505 61.0029 7.54214C59.6121 6.68989 58.0655 6.3324 56.2904 6.16362C54.5691 5.99996 52.4309 5.99998 49.7796 6Z"
          fill="#16DB65"
        />
      </g>
      <path
        opacity="0.4"
        d="M42.2778 63.9072C42.2778 65.0632 41.3408 66.0003 40.1848 66.0003C39.0289 66.0003 38.0918 65.0632 38.0918 63.9072V55.5352H42.2778V63.9072Z"
        fill="#16DB65"
      />
      <path
        opacity="0.5"
        d="M63.9065 38.0933C62.7506 38.0933 61.8135 39.0303 61.8135 40.1863V49.9537H65.9995V40.1863C65.9995 39.0303 65.0625 38.0933 63.9065 38.0933Z"
        fill="#16DB65"
      />
      <path
        d="M48.2284 49.8519C48 50.4033 48 51.1022 48 52.5C48 53.8978 48 54.5967 48.2284 55.148C48.5328 55.8831 49.1169 56.4672 49.8519 56.7716C50.4033 57 51.1022 57 52.5 57C53.8978 57 54.5967 57 55.148 56.7716C55.8831 56.4672 56.4672 55.8831 56.7716 55.148C57 54.5967 57 53.8978 57 52.5C57 51.1022 57 50.4033 56.7716 49.8519C56.4672 49.1169 55.8831 48.5328 55.148 48.2284C54.5967 48 53.8978 48 52.5 48C51.1022 48 50.4033 48 49.8519 48.2284C49.1169 48.5328 48.5328 49.1169 48.2284 49.8519Z"
        fill="#16DB65"
      />
      <path
        opacity="0.7"
        d="M65.9987 55.6056V55.5352H61.8126C61.8126 56.8641 61.8115 57.756 61.7644 58.4466C61.7185 59.1184 61.6363 59.4455 61.5471 59.6608C61.193 60.5155 60.5139 61.1946 59.6592 61.5487C59.4439 61.6379 59.1168 61.7201 58.445 61.766C57.7544 61.8131 56.8625 61.8142 55.5335 61.8142H49.9521V66.0003H55.604C56.8444 66.0003 57.8798 66.0003 58.7299 65.9423C59.6148 65.8819 60.4507 65.7518 61.2611 65.4161C63.1416 64.6372 64.6356 63.1432 65.4145 61.2627C65.7502 60.4523 65.8803 59.6165 65.9407 58.7315C65.9987 57.8814 65.9987 56.846 65.9987 55.6056Z"
        fill="#16DB65"
      />
      <path
        opacity="0.6"
        d="M38.0918 49.8467V49.9537H42.2778C42.2778 47.9504 42.2804 46.6055 42.3847 45.5802C42.4854 44.5899 42.6635 44.1323 42.8657 43.8296C43.1202 43.4487 43.4473 43.1217 43.8282 42.8672C44.1308 42.665 44.5884 42.4869 45.5788 42.3861C46.6041 42.2818 47.949 42.2793 49.9523 42.2793H55.5337V38.0933H49.8452C47.9766 38.0932 46.4174 38.0932 45.1551 38.2216C43.8338 38.356 42.6076 38.6482 41.5025 39.3866C40.6646 39.9465 39.9451 40.666 39.3852 41.504C38.6468 42.6091 38.3545 43.8353 38.2201 45.1566C38.0917 46.4189 38.0918 47.9782 38.0918 49.8467Z"
        fill="#16DB65"
      />
      <path
        d="M16.5259 56.0711C17.1809 56.5359 18.1086 56.5359 19.9641 56.5359C21.8196 56.5359 22.7473 56.5359 23.4024 56.0711C23.6335 55.9071 23.8353 55.7053 23.9993 55.4741C24.4641 54.8191 24.4641 53.8914 24.4641 52.0359C24.4641 50.1804 24.4641 49.2527 23.9993 48.5976C23.8353 48.3665 23.6335 48.1647 23.4024 48.0007C22.7473 47.5359 21.8195 47.5359 19.9641 47.5359C18.1086 47.5359 17.1809 47.5359 16.5259 48.0007C16.2947 48.1647 16.0929 48.3665 15.9289 48.5976C15.4641 49.2527 15.4641 50.1804 15.4641 52.0359C15.4641 53.8914 15.4641 54.8191 15.9289 55.4741C16.0929 55.7053 16.2947 55.9071 16.5259 56.0711Z"
        fill="#16DB65"
      />
      <path
        d="M19.9636 24.4646C18.1082 24.4646 17.1804 24.4646 16.5254 23.9998C16.2942 23.8358 16.0924 23.634 15.9284 23.4029C15.4636 22.7478 15.4636 21.8201 15.4636 19.9646C15.4636 18.1091 15.4636 17.1814 15.9284 16.5263C16.0924 16.2952 16.2942 16.0934 16.5254 15.9294C17.1804 15.4646 18.1082 15.4646 19.9636 15.4646C21.8191 15.4646 22.7468 15.4646 23.4019 15.9294C23.633 16.0934 23.8348 16.2952 23.9988 16.5263C24.4636 17.1814 24.4636 18.1091 24.4636 19.9646C24.4636 21.8201 24.4636 22.7478 23.9988 23.4029C23.8348 23.634 23.633 23.8358 23.4019 23.9998C22.7468 24.4646 21.8191 24.4646 19.9636 24.4646Z"
        fill="#16DB65"
      />
      <path
        d="M48.5984 24.0005C49.2534 24.4653 50.1812 24.4653 52.0366 24.4653C53.8921 24.4653 54.8198 24.4653 55.4749 24.0005C55.706 23.8365 55.9078 23.6347 56.0718 23.4035C56.5366 22.7485 56.5366 21.8207 56.5366 19.9653C56.5366 18.1098 56.5366 17.1821 56.0718 16.527C55.9078 16.2959 55.706 16.0941 55.4749 15.9301C54.8198 15.4653 53.8921 15.4653 52.0366 15.4653C50.1812 15.4653 49.2534 15.4653 48.5984 15.9301C48.3672 16.0941 48.1654 16.2959 48.0014 16.527C47.5366 17.1821 47.5366 18.1099 47.5366 19.9653C47.5366 21.8208 47.5366 22.7485 48.0014 23.4035C48.1654 23.6347 48.3672 23.8365 48.5984 24.0005Z"
        fill="#16DB65"
      />
    </SvgIcon>
  );
};
