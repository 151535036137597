import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const DigitalIcon: FC<IconProps> = ({ className, width = 72, height = width }) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54 24C54 27.3137 51.3137 30 48 30C44.6863 30 42 27.3137 42 24C42 20.6863 44.6863 18 48 18C51.3137 18 54 20.6863 54 24Z"
        fill="#16DB65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8279 3.75H36.1721C43.0973 3.74996 48.5244 3.74993 52.7589 4.31924C57.0931 4.90196 60.5132 6.11798 63.1976 8.80241C65.882 11.4868 67.098 14.9069 67.6808 19.2411C68.2501 23.4756 68.25 28.9027 68.25 35.8279V36.0927C68.25 41.8191 68.25 46.5068 67.939 50.3236C67.6265 54.1592 66.9861 57.3643 65.5527 60.0262C64.9204 61.2003 64.1435 62.2517 63.1976 63.1976C60.5132 65.882 57.0931 67.098 52.7589 67.6808C48.5244 68.2501 43.0973 68.25 36.1721 68.25H35.8279C28.9027 68.25 23.4756 68.2501 19.2411 67.6808C14.9069 67.098 11.4868 65.882 8.80241 63.1976C6.42257 60.8178 5.19359 57.8557 4.54005 54.1813C3.89805 50.5718 3.78061 46.0809 3.75621 40.5044C3.75 39.086 3.75 37.5858 3.75 36.003L3.75 35.8279C3.74996 28.9027 3.74993 23.4756 4.31924 19.2411C4.90196 14.9069 6.11798 11.4868 8.80241 8.80241C11.4868 6.11798 14.9069 4.90196 19.2411 4.31924C23.4756 3.74993 28.9027 3.74996 35.8279 3.75ZM19.8407 8.77911C16.0055 9.29474 13.6934 10.2754 11.9844 11.9844C10.2754 13.6934 9.29474 16.0055 8.77911 19.8407C8.25478 23.7407 8.25 28.8653 8.25 36C8.25 37.5861 8.25001 39.0771 8.25616 40.4847C8.28076 46.1071 8.40642 50.2218 8.97052 53.3933C9.52308 56.4999 10.4632 58.4944 11.9844 60.0156C13.6934 61.7246 16.0055 62.7053 19.8407 63.2209C23.7407 63.7452 28.8653 63.75 36 63.75C43.1347 63.75 48.2593 63.7452 52.1593 63.2209C55.9945 62.7053 58.3066 61.7246 60.0156 60.0156C60.6454 59.3858 61.1616 58.6893 61.5906 57.8927C62.5856 56.0449 63.1593 53.5734 63.4539 49.9582C63.7483 46.3447 63.75 41.8376 63.75 36C63.75 28.8653 63.7452 23.7407 63.2209 19.8407C62.7053 16.0055 61.7246 13.6934 60.0156 11.9844C58.3066 10.2754 55.9945 9.29474 52.1593 8.77911C48.2593 8.25478 43.1347 8.25 36 8.25C28.8653 8.25 23.7407 8.25478 19.8407 8.77911Z"
        fill="#16DB65"
      />
      <path
        opacity="0.4"
        d="M61.8203 57.439L53.329 49.7969C50.2104 46.9901 45.5661 46.7106 42.1334 49.1231L41.2388 49.7518C38.8534 51.4283 35.608 51.1472 33.5463 49.0855L20.6771 36.2163C18.1085 33.6477 13.9882 33.5105 11.2544 35.9025L8.25098 38.5305C8.25182 39.1995 8.25334 39.8506 8.25612 40.4847C8.28071 46.1071 8.40637 50.2218 8.97047 53.3933C9.52303 56.4999 10.4632 58.4944 11.9843 60.0156C13.6933 61.7246 16.0055 62.7053 19.8407 63.2209C23.7406 63.7452 28.8653 63.75 36 63.75C43.1346 63.75 48.2593 63.7452 52.1592 63.2209C55.9944 62.7053 58.3066 61.7246 60.0156 60.0156C60.6453 59.3858 61.1615 58.6893 61.5905 57.8927C61.6698 57.7455 61.7464 57.5944 61.8203 57.439Z"
        fill="#16DB65"
      />
    </SvgIcon>
  );
};
