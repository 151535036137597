import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const FinanceIcon: FC<IconProps> = ({ className, width = 72, height = width }) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M9.51472 21.5147C12.0461 18.9833 15.6886 18.2751 21.7554 18.077L26.2562 18.0056C27.4305 18 28.6765 18 30 18H42C43.3235 18 44.5695 18 45.7438 18.0056L50.2446 18.077C56.3114 18.2751 59.9539 18.9833 62.4853 21.5147C66 25.0294 66 30.6863 66 42C66 53.3137 66 58.9706 62.4853 62.4853C58.9706 66 53.3137 66 42 66H42H29.9999H29.9999C18.6863 66 13.0294 66 9.51472 62.4853C6 58.9706 6 53.3137 6 42C6 30.6863 6 25.0294 9.51472 21.5147Z"
        fill="#16DB65"
      />
      <path
        d="M38.25 30C38.25 28.7574 37.2426 27.75 36 27.75C34.7574 27.75 33.75 28.7574 33.75 30V30.0307C30.4836 30.8536 27.75 33.429 27.75 37C27.75 41.3708 31.8452 44.25 36 44.25C38.4726 44.25 39.75 45.8479 39.75 47C39.75 48.1521 38.4726 49.75 36 49.75C33.5274 49.75 32.25 48.1521 32.25 47C32.25 45.7574 31.2426 44.75 30 44.75C28.7574 44.75 27.75 45.7574 27.75 47C27.75 50.571 30.4836 53.1464 33.75 53.9693V54C33.75 55.2426 34.7574 56.25 36 56.25C37.2426 56.25 38.25 55.2426 38.25 54V53.9693C41.5164 53.1464 44.25 50.571 44.25 47C44.25 42.6292 40.1548 39.75 36 39.75C33.5274 39.75 32.25 38.1521 32.25 37C32.25 35.8479 33.5274 34.25 36 34.25C38.4726 34.25 39.75 35.8479 39.75 37C39.75 38.2426 40.7574 39.25 42 39.25C43.2426 39.25 44.25 38.2426 44.25 37C44.25 33.429 41.5164 30.8536 38.25 30.0307V30Z"
        fill="#16DB65"
      />
      <path
        d="M36.1565 3.75H35.8445C33.1491 3.74992 30.9014 3.74985 29.117 3.98974C27.2337 4.24296 25.5333 4.79997 24.1669 6.16638C22.8004 7.53279 22.2434 9.23319 21.9902 11.1166C21.8182 12.3957 21.7696 15.4544 21.7559 18.077L26.2567 18.0056C26.2703 15.4657 26.3135 12.7322 26.4501 11.7162C26.6362 10.3318 26.9579 9.73931 27.3488 9.34836C27.7398 8.95741 28.3323 8.63574 29.7167 8.44962C31.1658 8.25479 33.1085 8.25001 36.0005 8.25001C38.8925 8.25001 40.8352 8.25479 42.2843 8.44962C43.6687 8.63574 44.2612 8.95741 44.6521 9.34836C45.0431 9.73931 45.3648 10.3318 45.5509 11.7162C45.6875 12.7322 45.7307 15.4657 45.7443 18.0056L50.2451 18.077C50.2314 15.4544 50.1827 12.3957 50.0107 11.1166C49.7575 9.23319 49.2005 7.53279 47.8341 6.16638C46.4677 4.79997 44.7673 4.24296 42.8839 3.98974C41.0996 3.74985 38.8519 3.74992 36.1565 3.75Z"
        fill="#16DB65"
      />
    </SvgIcon>
  );
};
