import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const MarketplaceIcon: FC<IconProps> = ({ className, width = 72, height = width }) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M42.1548 6C47.1955 5.99996 51.1881 5.99992 54.3128 6.42735C57.5285 6.86725 60.1314 7.79409 62.184 9.88254C64.2367 11.971 65.1476 14.6192 65.58 17.8911C66.0001 21.0703 66 25.1326 66 30.2611V30.2612V41.739V41.739C66 46.8676 66.0001 50.9297 65.58 54.1089C65.1476 57.3808 64.2367 60.029 62.184 62.1175C60.1314 64.2059 57.5285 65.1328 54.3128 65.5726C51.1881 66.0001 47.1954 66 42.1548 66H42.1547H41.8451H41.8451C36.8045 66 32.8119 66.0001 29.6873 65.5726C26.4715 65.1328 23.8686 64.2059 21.816 62.1175C19.7633 60.029 18.8524 57.3808 18.42 54.1089C17.9999 50.9297 18 46.8675 18 41.7388V30.2611V30.2611C18 25.1325 17.9999 21.0703 18.42 17.8911C18.8524 14.6192 19.7633 11.971 21.816 9.88254C23.8686 7.79409 26.4715 6.86725 29.6873 6.42735C32.8119 5.99992 36.8045 5.99996 41.8452 6H41.8453H42.1548H42.1548Z"
        fill="#16DB65"
      />
      <path
        d="M36 54.75C34.7574 54.75 33.75 55.7574 33.75 57C33.75 58.2426 34.7574 59.25 36 59.25H51C52.2426 59.25 53.25 58.2426 53.25 57C53.25 55.7574 52.2426 54.75 51 54.75H36Z"
        fill="#16DB65"
      />
      <path
        d="M6 43.5C6 39.2866 6 37.1799 7.01118 35.6666C7.44893 35.0114 8.01144 34.4489 8.66658 34.0112C10.1799 33 12.2866 33 16.5 33C20.7134 33 22.8201 33 24.3334 34.0112C24.9886 34.4489 25.5511 35.0114 25.9888 35.6666C27 37.1799 27 39.2866 27 43.5V55.5C27 59.7134 27 61.8201 25.9888 63.3334C25.5511 63.9886 24.9886 64.5511 24.3334 64.9888C22.8201 66 20.7134 66 16.5 66C12.2866 66 10.1799 66 8.66658 64.9888C8.01144 64.5511 7.44893 63.9886 7.01118 63.3334C6 61.8201 6 59.7134 6 55.5V43.5Z"
        fill="#16DB65"
      />
    </SvgIcon>
  );
};
