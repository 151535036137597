import { SvgIcon } from '@mui/material';
import { FC } from 'react';

import { IconProps } from '../types';

export const GamingIcon: FC<IconProps> = ({ className, width = 72, height = width }) => {
  return (
    <SvgIcon
      className={className}
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M32.0006 18.4033L30.4949 17.3377C28.3459 15.8168 25.7779 15 23.1451 15H21.3077C20.0951 15 18.8703 15.0765 17.7274 15.4818C10.5793 18.017 5.66246 27.2851 6.01811 48.3079C6.08977 52.5436 7.0809 57.225 10.9027 59.0527C12.0936 59.6222 13.49 60 15.0801 60C16.9882 60 18.5034 59.4561 19.6729 58.6896C20.8992 57.8858 21.979 56.8412 23.0583 55.797L23.0584 55.7969C24.3921 54.5065 25.7253 53.2168 27.3337 52.3827C29.0856 51.4742 31.0301 51 33.0034 51H38.9966C40.9699 51 42.9144 51.4742 44.6663 52.3827C46.2747 53.2167 47.6078 54.5065 48.9416 55.7969L48.9416 55.7969C50.021 56.8412 51.1008 57.8858 52.3271 58.6896C53.4966 59.4561 55.0118 60 56.9199 60C58.51 60 59.9064 59.6222 61.0973 59.0527C64.9191 57.225 65.9102 52.5436 65.9819 48.3079C66.3375 27.2851 61.4206 18.017 54.2725 15.4818C53.1297 15.0765 51.9049 15 50.6923 15H48.8548C46.2221 15 43.6542 15.8168 41.5052 17.3377L39.9995 18.4032C38.8301 19.2309 37.4327 19.6753 36 19.6753C34.5674 19.6753 33.17 19.2309 32.0006 18.4033Z"
        fill="#16DB65"
      />
      <path
        d="M50.25 27C51.4926 27 52.5 28.0074 52.5 29.25C52.5 30.4926 51.4926 31.5 50.25 31.5C49.0074 31.5 48 30.4926 48 29.25C48 28.0074 49.0074 27 50.25 27Z"
        fill="#16DB65"
      />
      <path
        d="M22.5 27.75C23.7426 27.75 24.75 28.7574 24.75 30V32.25H27C28.2426 32.25 29.25 33.2574 29.25 34.5C29.25 35.7426 28.2426 36.75 27 36.75H24.75V39C24.75 40.2426 23.7426 41.25 22.5 41.25C21.2574 41.25 20.25 40.2426 20.25 39V36.75H18C16.7574 36.75 15.75 35.7426 15.75 34.5C15.75 33.2574 16.7574 32.25 18 32.25H20.25V30C20.25 28.7574 21.2574 27.75 22.5 27.75Z"
        fill="#16DB65"
      />
      <path
        d="M57 33.75C57 34.9926 55.9926 36 54.75 36C53.5074 36 52.5 34.9926 52.5 33.75C52.5 32.5074 53.5074 31.5 54.75 31.5C55.9926 31.5 57 32.5074 57 33.75Z"
        fill="#16DB65"
      />
      <path
        d="M45.75 36C46.9926 36 48 34.9926 48 33.75C48 32.5074 46.9926 31.5 45.75 31.5C44.5074 31.5 43.5 32.5074 43.5 33.75C43.5 34.9926 44.5074 36 45.75 36Z"
        fill="#16DB65"
      />
      <path
        d="M52.5 38.25C52.5 37.0074 51.4926 36 50.25 36C49.0074 36 48 37.0074 48 38.25C48 39.4926 49.0074 40.5 50.25 40.5C51.4926 40.5 52.5 39.4926 52.5 38.25Z"
        fill="#16DB65"
      />
    </SvgIcon>
  );
};
